<template>
    <el-dialog
            :title="!inputForm.id ? '新增' : '修改'"
            :close-on-click-modal="false"
            append-to-body
            v-dialogDrag
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading" ref="inputForm"
                 @keyup.enter.native="doSubmit()"
                 label-width="80px" @submit.native.prevent>
            <el-form-item label="标签" prop="label">
                <el-input v-model.trim="inputForm.label" maxlength="50" placeholder="标签"></el-input>
            </el-form-item>
            <el-form-item label="键值" prop="value">
                <el-input v-model.trim="inputForm.value" maxlength="50" placeholder="键值"></el-input>
            </el-form-item>
            <el-form-item label="排序号" prop="sort">
                <el-input-number :min="1" :max="9999" v-model="inputForm.sort" placeholder="排序号"></el-input-number>
            </el-form-item>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                loading: false,
                inputForm: {
                    id: '',
                    dictTypeId: '',
                    label: '',
                    value: '',
                    sort: 1
                },
                dataRule: {
                    label: [
                        {required: true, message: '标签不能为空', trigger: 'blur'}
                    ],
                    value: [
                        {required: true, message: '标签不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            init(method, obj) {
                this.inputForm.id = obj.dictValueId
                this.inputForm.dictTypeId = obj.dictTypeId
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit' || method === 'view') { // 修改或者查看
                        this.$axios(this.api.auth.dictQueryDictValue, {id: this.inputForm.id}, 'get').then(data => {
                            if (data && data.status) {
                                this.inputForm = this.recover(this.inputForm, data.data)
                                this.inputForm.id = obj.dictValueId
                            }
                        })
                    }
                })
            },
            // 表单提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if (!this.inputForm.id) {
                            this.$axios(this.api.auth.dictSaveDictValue, this.inputForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.data)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                    this.$dictUtils.refreshDictList()
                                }
                            })
                        } else {
                            this.$axios(this.api.auth.updateDictValue, [this.inputForm], 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.data)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                    this.$dictUtils.refreshDictList()
                                }
                            })
                        }

                    }
                })
            }
        }
    }
</script>
